import Vue from 'vue'
import VueRouter from 'vue-router'

// 1.安装插件
Vue.use(VueRouter)

// 2.创建路由对象
const routes = [
	// 配置映射关系
	// {
	// 	path:'',
	// 	redirect:'/home'
	// },
	{
		path:'/',
		component:() => import('@/views/pages/home')
	},
	{
		path:'/details',
		component:() => import('@/views/pages/details')
	},
	{
		path:'/box',
		component:() => import('@/views/pages/box')
	},
	{
		path:'/list',
		component:() => import('@/views/pages/list')
	},
	{
		path:'/file',
		component:() => import('@/views/pages/file')
	},
	{
		path:'/money',
		component:() => import('@/views/pages/money')
	},
	{
		path:'/psnlist',
		component:() => import('@/views/pages/psnlist')
	},
]

const router = new VueRouter({
	routes,
	mode:'history'
})

//3.导出$router
export default router
