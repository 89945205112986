
<template>
    <div>
        <div class="details">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="details_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="details_top_img_min"></el-image>
            <div class="details_title">{{dataOne.title}}</div>
            <div class="details_createDate">{{newTime(dataOne.createDate)}}</div>
            <div v-html="dataOne.content" class="details_content"></div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                dataOne: {}
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {
            this.getDataOne(this.$route.query.dataId)
        },

        methods: {
            newTime(time) {
                return time.substring(0,10)
            },

            getDataOne(id) {
                this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/info/' + id,{

                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.dataOne = res.data.data
                })
            }
        }
    }
</script>


<style scoped>
    .details {
        position: relative;
    }

    .details_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .details_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .details_title {
        width: 80%;
        margin-left: 10%;
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .details_createDate {
        margin-top: 50px;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }

    .details_content {
        margin-top: 50px;
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-bottom: 50px;
        font-size: 18px;
    }

    .details_content img {
        width: 80%;
        height: auto;
    }

    video {
        width: 80%;
        height: auto;
    }
</style>
