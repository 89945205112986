import Vue from 'vue'
import Element from 'element-ui'
import '@/element-ui/theme/index.css'
import App from '@/App'
import router from './router'
import axios from "axios";
Vue.prototype.$axios = axios
Vue.prototype.$httpUrl = "http://175.31.163.106:8064"
// Vue.prototype.$httpUrl = "http://127.0.0.1:8064"
Vue.config.productionTip = false

Vue.use(Element, {
  size: 'default'
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
