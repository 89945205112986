<template>
    <div id="app">
        <div :class="topClass">
            <el-image @click="handleSelect('1')" fit="fill" src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="top_logo"></el-image>
<!--            <el-image @click="handleSelect('1')" fit="fill" src="http://222.168.10.38:8077/cczcimage/20231012/43e51d5c202e4882b4dc7025dd0b0ac2.png" class="top_logo1"></el-image>-->
            <el-menu class="top_nav" mode="horizontal" @select="handleSelect" background-color="black" text-color="white" active-text-color="white">
                <el-menu-item index="1">首页</el-menu-item>
                <el-submenu index="2">
                    <template slot="title">关于我们</template>
                    <el-menu-item :index="item.id"  v-for="(item, index) in menuList1" :key='index'>{{item.title}}</el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">仲裁指南</template>
                    <el-menu-item :index="item.id"  v-for="(item, index) in menuList2" :key='index'>{{item.title}}</el-menu-item>
                </el-submenu>
                <el-submenu index="4">
                    <template slot="title">仲裁员</template>
                    <el-menu-item index="4-1">仲裁员名册</el-menu-item>
                    <el-menu-item :index="item.id"  v-for="(item, index) in menuList3" :key='index'>{{item.title}}</el-menu-item>
                </el-submenu>
                <el-menu-item index="5">智能小仲</el-menu-item>
                <el-submenu index="6">
                    <template slot="title">在线服务</template>
                    <el-menu-item index="6-1">网上立案</el-menu-item>
                    <el-menu-item index="6-2">在线留言</el-menu-item>
                </el-submenu>
                <el-submenu index="7">
                    <template slot="title">仲裁规则</template>
                    <el-menu-item :index="item.id"  v-for="(item, index) in menuList4" :key='index'>{{item.title}}</el-menu-item>
                </el-submenu>
                <el-submenu index="8">
                    <template slot="title">信息公开</template>
                    <el-menu-item :index="item.id"  v-for="(item, index) in menuList5" :key='index'>{{item.title}}</el-menu-item>
                </el-submenu>
                <el-menu-item index="9">公告送达</el-menu-item>
                <el-menu-item index="10">新闻动态</el-menu-item>
            </el-menu>
<!--            <div class="top_bom" @click="handleSelect('11')">-->
<!--                <span style="padding: 5px;border-style: solid;border-width: 2px;border-color: white;border-radius: 5px;cursor: pointer;">在线仲裁立案</span>-->
<!--            </div>-->
        </div>
        <router-view class="main-view" :key="key"></router-view>
        <div class="bom">
<!--            <el-image src="http://222.168.10.38:8077/cczcimage/20231013/b885d60bf5ab4c11af43279196964bfe.png" class="bom_logo"></el-image>-->
<!--            <el-image src="http://222.168.10.38:8077/cczcimage/20231013/7173e8011ff54508a04c01202c998149.png" class="bom_wx"></el-image>-->
<!--            <el-image src="http://222.168.10.38:8077/cczcimage/20231020/dace3d88e1df4eb3baee1abead4bed85.jpg" class="bom_wx_1"></el-image>-->
<!--            <el-image src="http://222.168.10.38:8077/cczcimage/20231013/eb7b2dc036fc40808eac49cc44ca3983.png" class="bom_dy"></el-image>-->
<!--            <el-image src="http://222.168.10.38:8077/cczcimage/20231020/f649f0ce5058404ca5affdeb6d8885b1.png" class="bom_dy_1"></el-image>-->
<!--            <div class="bom_gz">关注我们</div>-->
<!--            <div class="bom_top"></div>-->
<!--            <div class="bom_list">-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">关于我们</div>-->
<!--                    <div class="bom_list_item_min1" v-for="(item, index) in menuList1" :key='index' @click="handleSelect(item.id)">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">仲裁指南</div>-->
<!--                    <div class="bom_list_item_min1" v-for="(item, index) in menuList2" :key='index' @click="handleSelect(item.id)">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">仲裁员</div>-->
<!--                    <div class="bom_list_item_min1" v-for="(item, index) in menuList3" :key='index' @click="handleSelect(item.id)">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">在线服务</div>-->
<!--                    <div class="bom_list_item_min1" @click="handleSelect('6-1')">网上立案</div>-->
<!--                    <div class="bom_list_item_min1" @click="handleSelect('6-2')">在线留言</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">仲裁规则</div>-->
<!--                    <div class="bom_list_item_min1" v-for="(item, index) in menuList4" :key='index' @click="handleSelect(item.id)">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min">信息公开</div>-->
<!--                    <div class="bom_list_item_min1" v-for="(item, index) in menuList5" :key='index' @click="handleSelect(item.id)">{{item.title}}</div>-->
<!--                </div>-->
<!--                <div class="bom_list_item">-->
<!--                    <div class="bom_list_item_min" @click="handleSelect('9')">公告送达</div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="friendList-title">友情链接</div>
            <div class="friendList">
                <a v-for="(item, index) in friendList" :key='index' class="friendList_item" :href="item.url" target="_blank">{{item.name}}</a>
            </div>
            <div class="bom_title1">Copyright&nbsp;&nbsp;&nbsp;&nbsp;2013-2019&nbsp;&nbsp;&nbsp;&nbsp;www.ccac.org.cn&nbsp;&nbsp;&nbsp;&nbsp;All&nbsp;&nbsp;Rights&nbsp;&nbsp;Reserved&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;主办单位名称:&nbsp;&nbsp;长春仲裁委员会</div>
            <div class="bom_title2"><el-image fit="fill" src="https://dcs.conac.cn/image/blue.png" class="bom_title2_img"></el-image>&nbsp;&nbsp;<a
                    href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: black;text-decoration:none;">吉公安网备32131102000333号</a>&nbsp;&nbsp;&nbsp;&nbsp;吉ICP备19002506号-1</div>
        </div>
    </div>
</template>

<script>
    import details from "./views/pages/details";

    export default {
        name: 'app',
        data () {
            return {
                menuList1: [],
                menuList2: [],
                menuList3: [],
                menuList4: [],
                menuList5: [],
                friendList: [],
                top: 0,
                topClass: "topDown"
            }
        },

        computed: {
            key() {
                return this.$route.path + Math.random();
            }
        },

        components: {

        },

        mounted() {
            window.addEventListener('scroll', () => {
                this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
            })
            this.getMenuList('1')
            this.getMenuList('2')
            this.getMenuList('3')
            this.getMenuList('4')
            this.getMenuList('5')
            this.getFriendList()
        },

        // 监听top值的变化
        watch:{
            top(newValue,oldValue){
                if (newValue > oldValue) {
                    this.topClass = "topUp"
                }
                else {
                    this.topClass = "topDown"
                }
            }
        },

        methods: {
            handleSelect(key) {
                if (key == "1") {
                    this.$router.push("/")
                }
                else if (key == "5") {
                    this.$alert('该模块正在开发中、敬请期待！', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
                else if (key == "4-1") {
                    this.$router.push("/psnlist")
                }
                else if (key == "6-1") {
                    window.open("http://122.112.232.88:8705/index.htm")
                }
                else if (key == "6-2") {
                    this.$router.push("/box")
                }
                else if (key == "9") {
                    this.$router.push({
                        path: "/list",
                        query: {
                            type: '6',
                        }
                    })
                }
                else if (key == "10") {
                    this.$router.push({
                        path: "/list",
                        query: {
                            type: '7',
                        }
                    })
                }
                else if (key == "11") {
                    window.open("http://122.112.232.88:8705/index.htm")
                }
                else {
                    this.newRouter('/details', key)
                }
            },



            newRouter(path, id){
                this.$router.push({
                    path: path,
                    query: {
                        dataId: id,
                    }
                })
            },


            getMenuList(type) {
                var params = {
                    page: 1,
                    limit: 100,
                    type: type
                }
                this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
                    params: params
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    if (type == '1') {
                        this.menuList1 = res.data.data.list
                    }
                    else if (type == '2') {
                        this.menuList2 = res.data.data.list
                    }
                    else if (type == '3') {
                        this.menuList3 = res.data.data.list
                    }
                    else if (type == '4') {
                        this.menuList4 = res.data.data.list
                    }
                    else if (type == '5') {
                        this.menuList5 = res.data.data.list
                    }
                })
            },

            getFriendList() {
                var params = {
                    page: 1,
                    limit: 100,
                }
                this.$axios.get(this.$httpUrl + '/CczcFriendUrl/cczcfriendurl/page',{
                    params: params
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.friendList = res.data.data.list
                })
            }
        },

        beforeDestroy() {

        },

    }
</script>

<style>
    html,body,#app{
        height: 100vh;
        width: 100%;
        margin:0;
        padding:0;
        background-color: #f5f5f5;
    }

    .topUp {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 999;
        height: 60px;
        line-height: 60px;
        background-color: #000000;
        animation: moveUp 1s forwards;
    }

    @keyframes moveUp {
        0% {
            /* 定义动画开始的状态 */
            transform: translateY(0);
        }
        100% {
            /* 定义动画结束的状态 */
            transform: translateY(-60px);
        }
    }

    .topDown {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 999;
        height: 60px;
        line-height: 60px;
        background-color: #000000;
        animation: moveDown 1s forwards;
    }

    @keyframes moveDown {
        0% {
            /* 定义动画开始的状态 */
            transform: translateY(-60px);
        }
        100% {
            /* 定义动画结束的状态 */
            transform: translateY(0px);
        }
    }

    .top_logo {
        position: absolute;
        width: 10%;
        height: auto;
        top: 5%;
        left: 5%;
    }

    .top_logo1 {
        position: absolute;
        width: 10%;
        height: auto;
        top: 25%;
        left: 5%;
    }

    .top_nav {
        position: absolute;
        font-weight: 900;
        width: 80%;
        /*background-color: #000000;*/
        top: 0px;
        left: 20%;
        height: 100%;
    }

    .top_bom {
        position: absolute;
        font-weight: 900;
        color: white;
        width: 10%;
        font-size: 14px;
        /*background-color: #000000;*/
        top: 0px;
        right: 0%;
        height: 60px;
        text-align: center;
        line-height: 60px;
    }

    .el-menu--horizontal {
        font-weight: 900;
        opacity: 0.6;
        border-bottom: 0px solid transparent;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: solid 0px #e6e6e6;
    }

    .el-menu-item {
        font-size: 18px;
        color: white;
        padding: 0 10px;
    }

    .el-submenu__title {
        font-size: 18px;
        color: white;
        padding: 0 10px;
    }

    .el-menu-item:hover {
        background: #000000 !important;
        color: white !important;
    }

    .el-submenu__title:hover {
        background: #000000 !important;
        color: white !important;
    }

    .el-menu-item.is-active {
        background: #000000 !important;
        color: white !important;
    }

    .el-submenu__title.is-active {
        background: #000000 !important;
        color: white !important;
    }

    .bom {
        position: relative;
        width: 100%;
        height: 200px;
        background-color: white;
    }

    .bom_logo {
        position: absolute;
        width: 12%;
        height: 30px;
        left: 10%;
        top: 10%;
    }

    .bom_dy {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 10%;
        top: 10%;
    }

    .bom_dy:hover + .bom_dy_1{
        opacity: 1
    }

    .bom_dy_1 {
        position: absolute;
        width: 200px;
        height: auto;
        right: 5%;
        top: -100%;
        opacity: 0
    }

    .bom_wx {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 13%;
        top: 10%;
    }

    .bom_wx:hover + .bom_wx_1{
        opacity: 1
    }

    .bom_wx_1 {
        position: absolute;
        width: 200px;
        height: auto;
        right: 5%;
        top: -105%;
        opacity: 0
    }

    .bom_gz {
        position: absolute;
        line-height: 30px;
        font-size: 14px;
        font-weight: 700;
        right: 16.5%;
        top: 10%;
    }

    .bom_top {
        position: absolute;
        width: 80%;
        left: 10%;
        top: 30%;
        border-style: solid;
        border-width: 1px;
        border-color: #E8E8E8;
    }

    .bom_list {
        position: absolute;
        display: flex;
        width: 80%;
        top: 25%;
        margin-left: 10%;
    }

    .bom_list_item {
        width: 12%;
        margin-left: calc(16% / 8);
        text-align: left;
    }

    .bom_list_item_min {
        font-size: 14px;
        margin-bottom: 15px;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
    }

    .bom_list_item_min1 {
        /*color: #696969;*/
        cursor: pointer;
        font-size: 11px;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
    }

    .friendList-title {
        position: relative;
        top: 20px;
        bottom: 20px;
        margin-left: 10%;
        line-height: 14px;
        font-size: 14px;
        font-weight: 700;
        border-left-style: solid;
        border-left-width: 3px;
        border-left-color: #007cc7;
        padding-left: 5px;
    }

    .friendList {
        position: relative;
        display: flex;
        width: 80%;
        left: 10%;
        top: 25%
    }

    .friendList_item {
        width: 8%;
        margin-left: calc(20% / 11);
        text-align: center;
        font-size: 11px;
        font-weight: 700;
        color: black;
        text-decoration:none;
    }

    .friendList_item:hover {
        color: #007cc7;
    }

    .bom_title1 {
        position: absolute;
        width: 80%;
        left: 10%;
        top: 60%;
        text-align: center;
        font-size: 14px;
    }

    .bom_title2 {
        position: absolute;
        width: 80%;
        left: 10%;
        top: 75%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
    }

    .bom_title2_img {
        width: 30px;
        height: 30px;
        vertical-align:middle;
    }
</style>
